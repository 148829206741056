import '@css/main.scss';
import '@js/bootstrap';

import { Header } from "@js/utils/Header";
import { Filters } from "@js/utils/Filters";
import { Slider } from "@js/utils/Slider";

import AOS from 'aos';
import 'aos/dist/aos.css';


window.onload = () => {
    AOS.init()
    new Header()
    new Filters()
    new Slider()
}
