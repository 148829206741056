import {Pagination, Navigation} from "swiper/modules";
import Swiper from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export class Slider {
    constructor()
    {
        this.slider = document.querySelectorAll('.swiper')

        if (this.slider.length > 0) {
            this.init();
        }
    }

    init()
    {
        this.slider.forEach((slider) => {
            const swiper = new Swiper(slider, {
                centeredSlides: false,
                slidesPerView: 1,
                modules: [Pagination, Navigation],
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });
        });
    }
}
