import gsap from 'gsap'

export class Header {
    tls_children = [];

    constructor()
    {
        this.banner = document.querySelector('.header--container')
        this.nav = this.banner.querySelector('.header-navigation--mobile')

        this.tl = gsap.timeline({paused: true});
        this.animation()

        const burger = document.querySelector('#burger')
        if (burger) {
            burger.addEventListener('click', evt => {
                evt.preventDefault()
                burger.classList.toggle('active')
                this.banner.classList.toggle('expanded')

                if (this.banner.classList.contains('expanded')) {
                    this.tl.play()
                } else {
                    this.tl.reverse()
                    for (const tl of this.tls_children) {
                        tl.reverse()
                    }
                }
            })
        }

        if (this.nav) {
            this.nav.querySelectorAll('.menu-item.menu-item-has-children > a').forEach(item => {
                const tl = gsap.timeline({paused: true});
                this.tls_children.push(tl)
                const parent = item.closest('.menu-item');
                this.animationItems(parent, tl)

                item.addEventListener('click', evt => {
                    evt.preventDefault()
                    parent.classList.toggle('active')

                    if (parent.classList.contains('active')) {
                        tl.play()
                    } else {
                        tl.reverse()
                    }
                })
            })

            this.clickAnchor()
        }

        window.onscroll = function () {
            const header = document.querySelector('header.header');
            if (window.pageYOffset > header.offsetTop) {
                header.classList.add("scroll");
            } else {
                header.classList.remove("scroll");
            }
        };

    }

    animation()
    {
        this.tl.to(this.nav, {x: 0})
    }

    animationItems(parentItem, tl)
    {
        const container = parentItem.querySelector('.sub-menu')
        if (container) {
            const menu_items = container.querySelectorAll(':scope > .menu-item')

            tl.fromTo(container, {display: 'none', visibility: 0, opacity: 0}, {display: 'flex', visibility: 1, opacity: 1})
            tl.fromTo(menu_items, {x: -15, opacity: 0}, {x: 0, opacity: 1, stagger: 0.15}, '-=.5')
        }
    }

    clickAnchor()
    {
        document.querySelectorAll('.header-navigation__menu > li:not(.menu-item-has-children)').forEach(item => {
            const href = item.querySelector('a').getAttribute('href')

            if (!href.includes('#')) {
                return;
            }

            item.addEventListener('click', evt => {
                console.log('click')
                document.querySelectorAll('.header-navigation__menu > li').forEach(item => {
                    item.classList.remove('current-menu-item');
                    item.classList.remove('active')
                });

                item.classList.add('active');

                const burger = document.querySelector('#burger')
                burger.classList.toggle('active')
                this.banner.classList.toggle('expanded')

                this.tl.reverse()
                for (const tl of this.tls_children) {
                    tl.reverse()
                }
            });
        });

    }

}
