import Isotope from 'isotope-layout';

export class Filters {
    constructor()
    {
        var grid = document.querySelector('.s-realizations-list');
        var filterButtonGroup = document.querySelector('.s-realizations-filters');
        var iso;

        if (grid && filterButtonGroup) {
            function onHashchange()
            {
                var hashFilter = getHashFilter();
                if (!hashFilter && iso) {
                    return;
                }
                if (!iso) {
                    iso = new Isotope(grid, {
                        itemSelector: '.c-simple-list__item',
                        layoutMode: 'fitRows',
                        filter: hashFilter || '',
                    });
                } else {
                    iso.arrange({
                        filter: hashFilter
                    });
                }

              // set selected class on button
                if (hashFilter) {
                    var checkedButton = filterButtonGroup.querySelector('.active');
                    if (checkedButton) {
                        checkedButton.classList.remove('active');
                    }
                    filterButtonGroup.querySelector('[data-filter="' + hashFilter + '"]').classList.add('active');
                }
            }

            window.addEventListener('hashchange', onHashchange);
            onHashchange();

            filterButtonGroup.addEventListener('click', function (event) {
                var filterAttr = event.target.getAttribute('data-filter');
                if (!filterAttr) {
                    return;
                }
                location.hash = 'filter=' + encodeURIComponent(filterAttr);
            });

            function getHashFilter()
            {
              // get filter=filterName
                var matches = location.hash.match(/filter=([^&]+)/i);
                var hashFilter = matches && matches[1];
                return hashFilter && decodeURIComponent(hashFilter);
            }
        }

    }
}
